<template>
  <div class="app-container light-control-page">
    <el-tabs v-model="activeName">
      <el-tab-pane label="锦绣一期" disabled name="first">锦绣一期</el-tab-pane>
      <el-tab-pane label="锦绣二期" name="second">
        <el-row :gutter="40">
          <el-col :span="12" v-for="(item, index) in cardList" :key="index">
            <div class="card-container" v-loading="item.loading">
              <div class="header-line">
                <div class="label">
                  {{ item.headerLabel }}
                </div>
              </div>
              <div class="center-line">
                <div class="left-part">
                  <div class="detail-line">
                    <span class="label"> 手动控制开关: </span>
                    <el-switch
                      style="display: block"
                      v-model="item.lightValue"
                      active-color="#13ce66"
                      inactive-color="#ccc"
                      @change="lightChange(item)"
                    >
                    </el-switch>
                    <i
                      class="el-icon-warning-outline"
                      style="margin-left: 6px; color: #ff0000; font-size: 18px"
                      v-if="item.lightWarning"
                    ></i>
                  </div>
                  <div class="detail-line">
                    <span class="label"> 灯光开启时间: </span>
                    <el-time-picker
                      v-model="item.openTime"
                      value-format="HH:mm:ss"
                      placeholder="请选择开启时间"
                    >
                    </el-time-picker>
                  </div>
                  <div class="detail-line">
                    <span class="label"> 灯光关闭时间: </span>
                    <el-time-picker
                      v-model="item.closeTime"
                      value-format="HH:mm:ss"
                      placeholder="请选择结束时间"
                    >
                    </el-time-picker>
                  </div>
                </div>
                <div class="right-part">
                  <span class="button-span" @click="updateHandler(item.key)">
                    更新时间
                  </span>
                </div>
              </div>
              <div class="bottom-line">
                <div class="detail-line">
                  <span class="label-span"> 上次创建时间： </span>
                  <span>
                    {{
                      item.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss")
                    }}
                  </span>
                </div>
                <div class="detail-line">
                  <span class="label-span"> 上次灯光设定时间： </span>
                  <span>
                    {{ item.openTimeLabel }} 至 {{ item.closeTimeLabel }}
                  </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="锦绣三期" disabled name="third">锦绣三期</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  getAviationLampControlRule,
  getOutwardLampControlRule,
  updateAviationLampControlRule,
  updateOutwardLampControlRule,
  getSwtichValue,
  controlAviationLamp,
  controlOutwardLamp,
} from "@/api/ruge/vlink/rule/rule.js";
export default {
  data() {
    return {
      activeName: "second",
      cardList: [
        {
          key: "outwardLamp",
          loading: false,
          headerLabel: "外立面灯光控制",
          lightValue: true,
          lightWarning: false,
          openTime: null,
          closeTime: null,
          openTimeLabel: null,
          closeTimeLabel: null,
          lastUpdateDate: null,
        },
        {
          key: "aviationLamp",
          loading: false,
          headerLabel: "航空灯控制",
          lightValue: true,
          lightWarning: false,
          openTime: null,
          closeTime: null,
          openTimeLabel: null,
          closeTimeLabel: null,
          lastUpdateDate: null,
        },
      ],
    };
  },
  mounted() {
    this.initDatas();
  },
  methods: {
    lightChange(item) {
      // "outwardLamp" | "aviationLamp"
      const { lightValue, key } = item || {};
      item.loading = true;
      if (key === "outwardLamp") {
        controlOutwardLamp({
          status: lightValue,
        }).finally(() => {
          setTimeout(() => {
            this.$message.success(`${lightValue ? "开" : "关"}灯成功！`);
            item.loading = false;
            this.getSwitchDatas();
          }, 1000 * 60);
        });
      } else if (key === "aviationLamp") {
        controlAviationLamp({
          status: lightValue,
        }).finally(() => {
          setTimeout(() => {
            this.$message.success(`${lightValue ? "开" : "关"}灯成功！`);
            item.loading = false;
            this.getSwitchDatas();
          }, 1000 * 5);
        });
      }
    },
    initDatas() {
      this.getAviationLampRules();
      this.getOutwardLampRules();
      this.getSwitchDatas();
    },
    getSwitchDatas() {
      getSwtichValue().then((res) => {
        // 191的loop3和192的loop3是航空灯，其他都是外立面
        // 航空灯
        let aviationTotal = 0;
        let aviationOpen = 0;
        let aviationClose = 0;
        // 外立面
        let outwardTotal = 0;
        let outwardOpen = 0;
        let outwardClose = 0;
        res.forEach((item) => {
          const { deviceName } = item || {};
          if (item.shadow && item.shadow.length) {
            item.shadow.forEach((singleShadow) => {
              for (let singleReported in singleShadow.reported) {
                if (
                  ["GS2-Philips-191", "GS2-Philips-192"].includes(deviceName) &&
                  singleReported === "loop3"
                ) {
                  aviationTotal++;
                  if (singleShadow.reported[singleReported].value === "false") {
                    aviationClose++;
                  } else {
                    aviationOpen++;
                  }
                } else {
                  outwardTotal++;
                  if (singleShadow.reported[singleReported].value === "false") {
                    outwardClose++;
                  } else {
                    outwardOpen++;
                  }
                }
              }
            });
          }
        });
        console.log(
          `航空灯总数：${aviationTotal}，开灯数：${aviationOpen}，关灯数：${aviationClose}`
        );
        console.log(
          `外立面灯总数：${outwardTotal}，开灯数：${outwardOpen}，关灯数：${outwardClose}`
        );
        this.setSwitchState(
          aviationTotal,
          aviationOpen,
          aviationClose,
          "aviationLamp"
        );
        this.setSwitchState(
          outwardTotal,
          outwardOpen,
          outwardClose,
          "outwardLamp"
        );
      });
    },
    setSwitchState(total, openCount, closeCount, type) {
      this.cardList.forEach((item) => {
        if (item.key === type) {
          if (total === openCount) {
            // 如果全都是开的，那开关就设置为开
            item.lightValue = true;
          } else if (total === closeCount) {
            // 如果全都是关的，那开关就设置为关
            item.lightValue = false;
          } else {
            // 如果开关数和总数对不上，开的数量多设置为开，关的数量多设置为关，并给红色感叹号提示
            item.lightWarning = true;
            item.lightValue = openCount >= closeCount;
          }
        }
      });
    },
    getAviationLampRules() {
      getAviationLampControlRule().then((res) => {
        this.updateDatas(res, "aviationLamp");
      });
    },
    getOutwardLampRules() {
      getOutwardLampControlRule().then((res) => {
        this.updateDatas(res, "outwardLamp");
      });
    },
    updateDatas(datas, key) {
      this.cardList.forEach((item) => {
        if (item.key === key) {
          item.openTimeLabel = datas.openTime;
          item.closeTimeLabel = datas.closeTime;
          item.lastUpdateDate = datas.lastUpdateDate;
        }
      });
    },
    updateHandler(key) {
      if (key === "aviationLamp") {
        this.aviationLampUpdate();
      } else {
        this.outwardLampUpdate();
      }
    },
    // 外立面灯光开启、关闭事件更新
    outwardLampUpdate() {
      const params = this.cardList.filter(
        (item) => item.key === "outwardLamp"
      )[0];
      if (!params.openTime && !params.closeTime) {
        this.$message.warning("请选择更新时间！");
        return;
      }
      params.loading = true;
      updateOutwardLampControlRule({
        openTime: params.openTime || params.openTimeLabel,
        closeTime: params.closeTime || params.closeTimeLabel,
      })
        .then(() => {
          this.$message.success("外立面灯光开启、关闭时间更新成功！");
        })
        .finally(() => {
          params.loading = false;
          params.openTime = "";
          params.closeTime = "";
          this.getOutwardLampRules();
        });
    },
    // 航空灯光开启、关闭事件更新
    aviationLampUpdate() {
      const params = this.cardList.filter(
        (item) => item.key === "aviationLamp"
      )[0];
      if (!params.openTime && !params.closeTime) {
        this.$message.warning("请选择更新时间！");
        return;
      }
      params.loading = true;
      updateAviationLampControlRule({
        openTime: params.openTime || params.openTimeLabel,
        closeTime: params.closeTime || params.closeTimeLabel,
      })
        .then(() => {
          this.$message.success("航空灯光开启、关闭时间更新成功！");
        })
        .finally(() => {
          params.loading = false;
          params.openTime = "";
          params.closeTime = "";
          this.getAviationLampRules();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.light-control-page {
  .card-container {
    height: 358px;
    border: 1px solid #afb4cf42;
    background: #ffffff;
    box-shadow: 0px 4px 11px 4px rgba(175, 180, 207, 0.26);
    border-radius: 3px;
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .header-line {
      height: 58px;
      border-bottom: 1px solid #dfe1e6;
      padding-left: 30px;
      display: flex;
      align-items: center;
      .label {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 20px;
        color: #2f3941;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          background: #1a4cec;
          border-radius: 2px;
          position: absolute;
          left: -14px;
          top: 2px;
          height: 18px;
          width: 6px;
        }
      }
    }
    .center-line {
      flex: 1;
      padding-left: 30px;
      display: flex;
      .left-part {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        .detail-line {
          display: flex;
          align-items: center;
          .label {
            font-weight: 500;
            font-size: 12px;
            color: #303133;
            display: inline-block;
            width: 100px;
          }
        }
      }
      .right-part {
        padding-left: 25px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .button-span {
          cursor: pointer;
          font-weight: 500;
          font-size: 16px;
          color: #4374fa;
          position: relative;
          top: 28px;
        }
      }
    }
    .bottom-line {
      padding-left: 30px;
      height: 80px;
      width: 100%;
      background: #f8f8f8;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .detail-line {
        font-weight: 500;
        font-size: 12px;
        color: #909399;
        .label-span {
          display: inline-block;
          width: 110px;
        }
      }
    }
  }
}
</style>